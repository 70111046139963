<template>
  <div class="settings">
    <v-row>
      <v-col sm="12">
        <span class="headline">{{ pageName }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3">
        <v-select :items="timeValues" v-model="delayValue" label="TPR Delay Timer (seconds)"></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  data: () => ({
    pageName: 'Settings',
  }),
  computed: {
    ...mapGetters('settings', { timeValues: 'getTimeValues' }),
    delayValue: {
      get() {
        return this.$store.state.settings.delayValue;
      },
      set(value) {
        let data = {
          id: 'TPR Delay Timer',
          settingType: 'Timer',
          timeValues: this.timeValues,
          delayValue: value,
        };
        return this.$store.dispatch('settings/updateSettings', data);
      },
    },
  },
  methods: {
    ...mapActions('settings', { getSettings: 'getSettings' }),
  },
  created() {
    this.getSettings();
  },

  watch: {},
};
</script>

<style></style>
